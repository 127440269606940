import React from "react";
import { Box, Typography } from "@mui/material";
import image from "../assets/Banner1.jpg";
import Instagram from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { LinkedIn } from "@mui/icons-material";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    // <Box
    //   id="about-us"
    //   component="div"
    //   sx={{
    //     color: "white",
    //     paddingTop: "150px",
    //     width: "100%",
    //     // textAlign: "center",
    //     paddingLeft: "100px",
    //     paddingRight: "100px",
    //   }}>

    //   <Box
    //     component="div"
    //     sx={{
    //       display: "flex",
    //       flexDirection: "row",
    //       gap: 10,
    //       justifyContent: "center",
    //     }}>
    //     <Box component="div" sx={{ width: "50%" }}>
    //       <Typography sx={{ fontSize: 40 }}>ABOUT US</Typography>
    //       <Typography>
    //         As a leading truck dispatching company, we specialize in providing
    //         comprehensive dispatching services to owner-operators and trucking
    //         companies. Our commitment is to streamline your operations by
    //         managing nearly every aspect of logistics with precision, care, and
    //         security. We understand the importance of delivering products safely
    //         and on time, and we excel in ensuring wide-reaching, punctual
    //         shipments that meet your business needs. Our dedicated team takes
    //         the complexities of logistics off your shoulders, offering tailored
    //         solutions that enhance efficiency and profitability. Whether it’s
    //         optimizing routes for cost-effective travel or ensuring real-time
    //         tracking for seamless communication, we prioritize your success at
    //         every step. By partnering with us, you can focus on driving while we
    //         handle load planning, driver scheduling, and other operational
    //         details. Our services are designed to foster long-term
    //         relationships, built on trust, reliability, and transparent
    //         communication with both drivers and customers.
    //       </Typography>
    //       <Typography
    //         component="div"
    //         sx={{ display: "flex", justifyContent: "left", gap: 3, pt: 3 }}>
    //         <Instagram />
    //         <Link
    //           to="https://www.linkedin.com/company/hexalinks-llc/"
    //           target="_blank"
    //           rel="noopener noreferrer">
    //           <LinkedIn />
    //         </Link>
    //         <FacebookIcon />
    //       </Typography>
    //     </Box>
    //     <Box component="div" sx={{ width: "50%" }}>
    //       <Box component="div" sx={{ width: "100%" }}>
    //         <img src={image} width="100%" height="100%" alt="" />
    //       </Box>
    //     </Box>
    //   </Box>
    // </Box>
    <Box
      id="about-us"
      component="div"
      sx={{
        color: "white",
        paddingTop: { xs: "50px", sm: "100px", md: "150px" },
        width: "100%",
        paddingLeft: { xs: "20px", sm: "50px", md: "100px" },
        paddingRight: { xs: "20px", sm: "50px", md: "100px" },
      }}>
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Stack on small screens
          gap: { xs: 5, md: 10 },
          justifyContent: "center",
          alignItems: "center", // Center vertically on smaller screens
        }}>
        {/* Text Section */}
        <Box component="div" sx={{ width: { xs: "100%", md: "50%" } }}>
          <Typography
            sx={{
              fontSize: { xs: "24px", sm: "32px", md: "40px" },
              textAlign: { xs: "center", md: "left" },
            }}>
            ABOUT US
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
              textAlign: { xs: "justify", md: "left" },
            }}>
            As a leading truck dispatching company, we specialize in providing
            comprehensive dispatching services to owner-operators and trucking
            companies. Our commitment is to streamline your operations by
            managing nearly every aspect of logistics with precision, care, and
            security. We understand the importance of delivering products safely
            and on time, and we excel in ensuring wide-reaching, punctual
            shipments that meet your business needs. Our dedicated team takes
            the complexities of logistics off your shoulders, offering tailored
            solutions that enhance efficiency and profitability. Whether it’s
            optimizing routes for cost-effective travel or ensuring real-time
            tracking for seamless communication, we prioritize your success at
            every step. By partnering with us, you can focus on driving while we
            handle load planning, driver scheduling, and other operational
            details. Our services are designed to foster long-term
            relationships, built on trust, reliability, and transparent
            communication with both drivers and customers.
          </Typography>
          <Typography
            component="div"
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "left" },
              gap: 3,
              pt: 3,
            }}>
            <Instagram />
            <Link
              to="https://www.linkedin.com/company/hexalinks-llc/"
              target="_blank"
              rel="noopener noreferrer">
              <LinkedIn />
            </Link>
            <FacebookIcon />
          </Typography>
        </Box>

        {/* Image Section */}
        <Box
          component="div"
          sx={{
            width: { xs: "100%", md: "50%" },
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
          }}>
          <img
            src={image}
            style={{
              width: "100%",
              maxWidth: "500px", // Limit image size
              height: "auto",
              borderRadius: "8px",
            }}
            alt="About Us"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
