import React, { useRef } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { Call, Home } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { FormControlLabel, Checkbox, Link } from "@mui/material";

const Contact = () => {
  const form = useRef();
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const submit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_x1m6ilf",
        "template_0pf76ww",
        form.current,
        "a4po6X2O4htj_mvAE"
      )
      .then(
        () => {
          toast.success("Successfully Submitted", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        },
        (error) => {
          toast.error("Failed", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
  };
  return (
    // <Box
    //   id="contact"
    //   component="div"
    //   sx={{
    //     color: "white",
    //     paddingTop: "150px",
    //     width: "100%",
    //     paddingLeft: "100px",
    //     paddingRight: "100px",
    //     paddingBottom: "100px",
    //   }}>
    //   <ToastContainer />

    //   <Typography sx={{ textAlign: "center", fontSize: 40 }}>
    //     Get In Touch With Us
    //   </Typography>
    //   <Box
    //     component="div"
    //     sx={{
    //       display: "flex",
    //       flexDirection: "row",
    //       justifyContent: "center",
    //       gap: 10,
    //       paddingTop: "50px",
    //     }}>
    //     <Box component="div" sx={{ width: "50%" }}>
    //       <Typography>
    //         At HexaLinks, we're here to help you navigate the logistics of your
    //         trucking business with ease. Whether you need reliable truck
    //         dispatching services, expert invoice and billing assistance, or
    //         strategic support to expand your business, our team is just a call
    //         away. For immediate assistance or inquiries, reach out to us at +1
    //         2013753601 or email us at dispatch@hexalinksllc.com. Let us handle
    //         the complexities so you can focus on maximizing your profits and
    //         growing your operations. Connect with us today and experience
    //         seamless, reliable service tailored to your needs.
    //       </Typography>
    //       <Box
    //         component="div"
    //         sx={{
    //           display: "flex",
    //           flexDirection: "column",
    //           paddingTop: "20px",
    //           gap: 1,
    //         }}>
    //         <Box
    //           component="div"
    //           sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
    //           <Home />
    //           <Typography>Sheridan, WY 82801, USA</Typography>
    //         </Box>
    //         <Box
    //           component="div"
    //           sx={{
    //             display: "flex",
    //             cursor: "pointer",
    //             flexDirection: "row",
    //             gap: 1,
    //           }}
    //           onClick={() => (window.location.href = "tel:+1234567890")}>
    //           <Call />
    //           <Typography>+1 2013753601</Typography>
    //         </Box>
    //       </Box>
    //     </Box>
    //     <form ref={form} onSubmit={submit}>
    //       <TextField
    //         label="Name"
    //         name="user_name"
    //         variant="outlined"
    //         fullWidth
    //         margin="normal"
    //         InputLabelProps={{
    //           style: { color: "white" }, // Label color
    //         }}
    //         sx={{
    //           "& .MuiInputBase-input": {
    //             color: "white", // Input text color
    //           },
    //           "& .MuiOutlinedInput-root": {
    //             "& fieldset": {
    //               borderColor: "white", // Default border color
    //             },
    //             "&:hover fieldset": {
    //               borderColor: "white", // On hover
    //             },
    //             "&.Mui-focused fieldset": {
    //               borderColor: "blue", // When focused
    //             },
    //           },
    //         }}
    //       />
    //       <TextField
    //         label="Email"
    //         name="user_email"
    //         variant="outlined"
    //         fullWidth
    //         margin="normal"
    //         InputLabelProps={{
    //           style: { color: "white" }, // Label color
    //         }}
    //         sx={{
    //           "& .MuiInputBase-input": {
    //             color: "white", // Input text color
    //           },
    //           "& .MuiOutlinedInput-root": {
    //             "& fieldset": {
    //               borderColor: "white", // Default border color
    //             },
    //             "&:hover fieldset": {
    //               borderColor: "white", // On hover
    //             },
    //             "&.Mui-focused fieldset": {
    //               borderColor: "blue", // When focused
    //             },
    //           },
    //         }}
    //       />
    //       <TextField
    //         label="Message"
    //         name="message"
    //         variant="outlined"
    //         fullWidth
    //         margin="normal"
    //         multiline
    //         rows={4}
    //         InputLabelProps={{
    //           style: { color: "white" }, // Label color
    //         }}
    //         sx={{
    //           "& .MuiInputBase-input": {
    //             color: "white", // Input text color
    //           },
    //           "& .MuiOutlinedInput-root": {
    //             "& fieldset": {
    //               borderColor: "white", // Default border color
    //             },
    //             "&:hover fieldset": {
    //               borderColor: "white", // On hover
    //             },
    //             "&.Mui-focused fieldset": {
    //               borderColor: "blue", // When focused
    //             },
    //           },
    //         }}
    //       />
    //       <Button
    //         type="submit"
    //         variant="contained"
    //         sx={{
    //           marginTop: 2,
    //           backgroundColor: "blue",
    //           color: "white",
    //           "&:hover": {
    //             backgroundColor: "darkblue",
    //           },
    //         }}>
    //         Send
    //       </Button>
    //     </form>

    //   </Box>
    // </Box>
    <Box
      id="contact"
      component="div"
      sx={{
        color: "white",
        paddingTop: { xs: "50px", sm: "100px", md: "150px" },
        paddingLeft: { xs: "20px", sm: "50px", md: "100px" },
        paddingRight: { xs: "20px", sm: "50px", md: "100px" },
        paddingBottom: { xs: "50px", sm: "75px", md: "100px" },
        width: "100%",
      }}>
      <ToastContainer />
      <Typography
        sx={{
          textAlign: "center",
          fontSize: { xs: "24px", sm: "32px", md: "40px" },
        }}>
        Get In Touch With Us
      </Typography>
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          gap: { xs: 5, md: 10 },
          paddingTop: "50px",
        }}>
        {/* Text Section */}
        <Box component="div" sx={{ width: { xs: "100%", md: "50%" } }}>
          <Typography
            sx={{
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
              textAlign: { xs: "justify", md: "left" },
            }}>
            At HexaLinks, we're here to help you navigate the logistics of your
            trucking business with ease. Whether you need reliable truck
            dispatching services, expert invoice and billing assistance, or
            strategic support to expand your business, our team is just a call
            away. For immediate assistance or inquiries, reach out to us at +1
            2013753601 or email us at dispatch@hexalinksllc.com. Let us handle
            the complexities so you can focus on maximizing your profits and
            growing your operations. Connect with us today and experience
            seamless, reliable service tailored to your needs.
          </Typography>
          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "20px",
              gap: 1,
            }}>
            <Box
              component="div"
              sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <Home />
              <Typography>Sheridan, WY 82801, USA</Typography>
            </Box>
            <Box
              component="div"
              sx={{
                display: "flex",
                cursor: "pointer",
                flexDirection: "row",
                gap: 1,
              }}
              onClick={() => (window.location.href = "tel:+1234567890")}>
              <Call />
              <Typography>+1 2013753601</Typography>
            </Box>
          </Box>
        </Box>

        {/* Form Section */}
        <form
          ref={form}
          onSubmit={submit}
          style={{ width: "100%", maxWidth: "500px" }}>
          <TextField
            label="Name"
            name="user_name"
            required
            variant="outlined"
            fullWidth
            margin="normal"
            InputLabelProps={{ style: { color: "white" } }}
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "white" },
                "&:hover fieldset": { borderColor: "white" },
                "&.Mui-focused fieldset": { borderColor: "blue" },
              },
            }}
          />
          <TextField
            label="Email"
            name="user_email"
            required
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            InputLabelProps={{ style: { color: "white" } }}
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "white" },
                "&:hover fieldset": { borderColor: "white" },
                "&.Mui-focused fieldset": { borderColor: "blue" },
              },
            }}
          />
          <TextField
            label="Message"
            name="message"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            multiline
            rows={4}
            InputLabelProps={{ style: { color: "white" } }}
            sx={{
              "& .MuiInputBase-input": { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "white" },
                "&:hover fieldset": { borderColor: "white" },
                "&.Mui-focused fieldset": { borderColor: "blue" },
              },
            }}
          />
          <FormControlLabel
            sx={{ width: "100%", display: "flex", gap: 1 }}
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                color="secondary"
                sx={{
                  background: "black",
                  color: "black",
                  "&.Mui-checked": {
                    color: "black",
                  },
                  "&:hover": {
                    backgroundColor: "#000000",
                  },
                }}
              />
            }
            label={
              <Typography variant="body2">
                By checking this box, you agree to be contacted via phone and
                email regarding your interest in our products and services. We
                will treat your data in accordance with our{" "}
                <Link href="/PrivacyPolicy" color="primary">
                  privacy policy
                </Link>
                .
              </Typography>
            }
          />
          <Button
            type="submit"
            variant="contained"
            disabled={!checked}
            sx={{
              marginTop: 2,
              width: "100%",
              backgroundColor: "blue",
              color: "white",
              "&:hover": { backgroundColor: "darkblue" },
              "&.Mui-disabled": {
                backgroundColor: "#cccccc",
                color: "#666666",
              },
            }}>
            Send
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default Contact;
