import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
const drawerWidth = 240;
const navItems = [
  { name: "Services", path: "/ServicesDetails", isScroll: false },
  { name: "About Us", path: "about-us", isScroll: true },
  { name: "Contact", path: "contact", isScroll: true },
  { name: "Privacy Policy", path: "/PrivacyPolicy", isScroll: false },
  { name: "Terms & Condition", path: "/TermsAndConditions", isScroll: false },
];
function Navigation(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // const drawer = (
  //   <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
  //     <Typography variant="h6" sx={{ my: 2 }}>
  //       HexaLinks
  //     </Typography>
  //     <Divider />
  //     <List>
  //       {navItems?.map((item, index) => (
  //         <ListItem key={index} disablePadding>
  //           <ListItemButton sx={{ textAlign: "center" }}>
  //             {item.isScroll ? (
  //               <ScrollLink
  //                 to={item.path}
  //                 smooth={true}
  //                 duration={500}
  //                 style={{
  //                   color: "white",
  //                   textDecoration: "none",
  //                   cursor: "pointer",
  //                 }}>
  //                 {item.name}
  //               </ScrollLink>
  //             ) : (
  //               <Link
  //                 to={item.path}
  //                 style={{ color: "white", textDecoration: "none" }}>
  //                 {item.name}
  //               </Link>
  //             )}
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //   </Box>
  // );

  // Rest of the code remains unchanged
  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        HexaLinks
      </Typography>
      <Divider />
      <List>
        {navItems?.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              {item.isScroll ? (
                <ScrollLink
                  to={item.path}
                  smooth={true}
                  duration={500}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleDrawerToggle();
                    setTimeout(() => {
                      document
                        .getElementById(item.path)
                        ?.scrollIntoView({ behavior: "smooth" });
                    }, 300); // Adjust delay based on animation duration
                  }}>
                  {item.name}
                </ScrollLink>
              ) : (
                <Link
                  to={item.path}
                  style={{ color: "white", textDecoration: "none" }}
                  onClick={handleDrawerToggle}>
                  {item.name}
                </Link>
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        sx={{
          bgcolor: "transparent",
          boxShadow: "none",
          position: "absolute",
        }}>
        <Toolbar sx={{ paddingTop: 3 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}>
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              display: { xs: "none", md: "block" },
              pr: 5,
              marginLeft: "auto",
            }}>
            {navItems.map((item) => (
              <Button
                key={item.name}
                sx={{
                  color: "black",
                  mr: 1,
                  fontSize: 20,
                  fontFamily: "Logo_Font",
                }}>
                {item.isScroll ? (
                  <ScrollLink
                    to={item.path}
                    smooth={true}
                    duration={500}
                    style={{
                      color: "black",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}>
                    {item.name}
                  </ScrollLink>
                ) : (
                  <RouterLink
                    to={item.path}
                    style={{ color: "black", textDecoration: "none" }}>
                    {item.name}
                  </RouterLink>
                )}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", xl: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "black",
              color: "white",
            },
          }}>
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default Navigation;
