import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Instagram from "@mui/icons-material/Instagram";
import { LinkedIn } from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
function Footer() {
  const theme = useTheme();
  return (
    <AppBar
      position="relative"
      sx={{
        top: "auto",
        bottom: 0,
        bgcolor: theme.palette.background.default,
        px: 20,
        py: 5,
      }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column-reverse", lg: "row" },
          gap: { xs: 5 },
        }}>
        <Box
          sx={{
            textAlign: "center",
          }}>
          <Typography component="div">
            <Typography variant="h6">Contact</Typography>
            <Typography>+1 2013753601</Typography>
            <Typography>dispatch@hexalinksllc.com</Typography>
            <Typography>Sheridan, WY 82801, USA</Typography>
          </Typography>
        </Box>
        <Box component="div">
          <Typography
            variant="h4"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: "center",
            }}>
            HexaLinks
          </Typography>
          <Typography
            component="div"
            sx={{ display: "flex", justifyContent: "center", gap: 3, pt: 2 }}>
            <Instagram />
            <Link
              to="https://www.linkedin.com/company/hexalinks-llc/"
              target="_blank"
              rel="noopener noreferrer">
              <LinkedIn />
            </Link>
            <FacebookIcon />
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
