import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import Logo from "../assets/Logo.png";
import { Link } from "react-router-dom";

const SerivcesDetails = () => {
  return (
    <Box component="div" sx={{ background: "white", height: "100%" }}>
      <Box>
        <Link to="/">
          <img src={Logo} width={100} height={100} alt="" />
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh", // Vertically center content
          px: { xs: 2, sm: 4, md: 8 }, // Equal padding for responsiveness
          width: "100%", // Full width
        }}>
        <Box
          sx={{
            maxWidth: "800px", // Restrict content width for better readability
            textAlign: "left", // Left-align the text
            padding: "10px 30px",
          }}>
          {/* Main Heading */}
          <Typography variant="h4" gutterBottom align="center">
            Services
          </Typography>

          {/* Truck Dispatching Services */}
          <Typography variant="h5" gutterBottom>
            1. Truck Dispatching Services
          </Typography>
          <Typography variant="body1" paragraph>
            As a truck dispatching company, we provide truck dispatching
            services to owner-operators and truck companies. We handle nearly
            all facets of logistics for various products carefully and securely.
            Additionally, we offer wide-reaching and on-time shipments.
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Benefits you can get through us:
          </Typography>
          <List>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Reliable communication with customers and drivers
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              High paying load with cost-effective dispatch services
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Long-term relationship
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Provide information about optimized routes
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Escalate your potential profits
            </ListItem>
          </List>

          <Typography variant="subtitle1" gutterBottom>
            Truck dispatch services we provide include:
          </Typography>
          <List>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Real-time tracking and routing deliveries to avoid delays
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Efficient load planning
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Responsive and seamless communication to prevent inefficiencies
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Ensure quick payment by completing billing services
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Problem solver experts resolving issues related to weather, date
              estimations, and driver scheduling
            </ListItem>
          </List>

          {/* Truck Dispatch Billing */}
          <Typography variant="h5" gutterBottom>
            2. Truck Dispatch Billing
          </Typography>
          <Typography variant="body1" paragraph>
            We provide invoice and billing services to help you maintain a
            steady cash flow. We effectively handle truck dispatch billing by
            managing payments and tracking outstanding balances in real-time.
            Our experts ensure expenditures are properly documented and
            compliant with standards.
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Our billing services include:
          </Typography>
          <List>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Accurate invoicing
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Real-time billing updates
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Standards compliance
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Timely follow-up
            </ListItem>
          </List>

          {/* Factoring Services */}
          <Typography variant="h5" gutterBottom>
            3. Factoring Services
          </Typography>
          <Typography variant="body1" paragraph>
            We offer factoring services to help you collect instant payment for
            outstanding invoices. Instead of waiting many days for payment, you
            can sell your invoices to us and receive funds to maintain smooth
            operations. Our services ensure you never have to wait for payments.
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Benefits of our factoring services:
          </Typography>
          <List>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Competitive rates
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Flexible terms
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Low fees
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Fast approval
            </ListItem>
          </List>

          {/* Company Formation */}
          <Typography variant="h5" gutterBottom>
            4. Company Formation
          </Typography>
          <Typography variant="body1" paragraph>
            Are you thinking of expanding your trucking business or current
            fleet but don’t have proper planning? Do not worry, we are here to
            ease your work. We provide company formation services to assist you
            in enlarging your business. Additionally, our experts create a solid
            business plan for your trucking business that traces your goals,
            financing, and operations.
          </Typography>

          {/* Negotiate the Best Rates */}
          <Typography variant="h5" gutterBottom>
            5. Negotiate the Best Rates
          </Typography>
          <Typography variant="body1" paragraph>
            We maximize your earnings as your success is our success. We offer
            the best rates to shippers and brokers on your behalf. We secure
            competitive rates for our customers due to our extensive industry
            knowledge and strong associations.
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Our negotiation services include:
          </Typography>
          <List>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Best rates
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Factor in fuel costs
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Extensive network
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Best freight opportunities
            </ListItem>
          </List>

          {/* TONU/Detention Assistance */}
          <Typography variant="h5" gutterBottom>
            6. TONU/Detention Assistance
          </Typography>
          <Typography variant="body1" paragraph>
            We provide TONU/Detention assistance to ensure you are fairly
            compensated when delays or other issues arise. We make sure to:
          </Typography>
          <List>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Claim TONU/Detention charges
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Follow-up and handle cancellations/delays
            </ListItem>
            <ListItem
              sx={{ display: "list-item", listStyleType: "disc", pl: 2 }}>
              Ensure maximum compensation
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default SerivcesDetails;
