import React from "react";
import { Box, Button, Typography } from "@mui/material";
import RegisteredSvg from "../assets/rrr.png";
import Banner from "../assets/Banner1111.jpg";
import Mobile from "../assets/TruckBannerPhone1.png";
import TemporaryDrawer from "../components/SideBar";

const Home = () => {
  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        height: "100vh",
        backgroundImage: { xs: `url(${Mobile})`, md: `url(${Banner})` },
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}>
      <Box
        sx={{
          display: "flex",
          paddingTop: { xs: "20%", sm: "15%", md: "3%" },
          justifyContent: { xs: "center", lg: "start" },
        }}>
        <Typography
          sx={{
            fontFamily: "Logo_Font",
            fontSize: { xs: "64px", sm: "130px", md: "150px" },
            color: "black",
          }}>
          HexaLinks
        </Typography>
        <Box sx={{ marginTop: { xs: "0", sm: "40px" } }}>
          <img src={RegisteredSvg} width={50} height={40} alt="svg" />
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          width: "100%",
          position: "absolute",
          bottom: { xs: "10px", md: "50px" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "center", md: "flex-end" },
          alignItems: "center",
          paddingRight: "2%",
          gap: { xs: "10px", md: "20px" },
        }}>
        <TemporaryDrawer />
        <Button
          variant="contained"
          sx={{
            // height: { xs: "6%",md:"8%" },
            width: { xs: "90%", md: "15%" },
            background: "black",
            // marginTop: "10px",
            padding: "10px 50px",
            fontSize: "20px",
            "&:hover": {
              backgroundColor: "#2F3645",
            },
            borderRadius: "10px",
          }}
          onClick={() => (window.location.href = "tel:+1234567890")}>
          Call Now
        </Button>
      </Box>
    </Box>
  );
};

export default Home;
