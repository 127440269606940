import { Box, Typography } from "@mui/material";
import React from "react";
import Bill from "../assets/BILL.png";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  return (
    // <Box sx={{ color: "white", textAlign: "center", height: "100%" }}>
    //   <Typography sx={{ fontSize: "40px", marginTop: "50px" }}>
    //     Services
    //   </Typography>

    //   <Box component="div" sx={{ paddingTop: "70px" }}>
    //     {/* First Row */}
    //     <Box
    //       component="div"
    //       sx={{
    //         display: "flex",
    //         flexDirection: "row",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         gap: 20, // Consistent gap
    //       }}>
    //       {[
    //         "Truck Dispatching services",
    //         "Truck dispatch billing",
    //         "Factoring services",
    //       ].map((text, index) => (
    //         <Box
    //           key={index}
    //           onClick={() => navigate("/ServicesDetails")}
    //           component="div"
    //           sx={{
    //             textAlign: "center",
    //             width: "200px", // Set equal width for all items
    //             cursor: "pointer",
    //           }}>
    //           <img
    //             src={Bill}
    //             alt=""
    //             style={{ width: "100px", height: "auto" }}
    //           />
    //           <Typography>{text}</Typography>
    //         </Box>
    //       ))}
    //     </Box>

    //     {/* Second Row */}
    //     <Box
    //       component="div"
    //       sx={{
    //         paddingTop: "70px",
    //         display: "flex",
    //         flexDirection: "row",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         gap: 20, // Consistent gap
    //       }}>
    //       {[
    //         "Company formation",
    //         "Negotiate the Best Rates",
    //         "TONU/Detention Assistance",
    //       ].map((text, index) => (
    //         <Box
    //           key={index}
    //           component="div"
    //           onClick={() => navigate("/ServicesDetails")}
    //           sx={{
    //             textAlign: "center",
    //             width: "200px", // Set equal width for all items
    //             cursor: "pointer",
    //           }}>
    //           <img
    //             src={Bill}
    //             alt=""
    //             style={{ width: "100px", height: "auto" }}
    //           />
    //           <Typography>{text}</Typography>
    //         </Box>
    //       ))}
    //     </Box>
    //   </Box>
    // </Box>
    <Box sx={{ color: "white", textAlign: "center", height: "100%" }}>
      <Typography
        sx={{
          fontSize: { xs: "24px", sm: "32px", md: "40px" }, // Responsive font size
          marginTop: { xs: "20px", sm: "30px", md: "50px" },
        }}>
        Services
      </Typography>

      <Box
        component="div"
        sx={{ paddingTop: { xs: "30px", sm: "50px", md: "70px" } }}>
        {/* First Row */}
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" }, // Stack items on small screens
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: 5, sm: 10, md: 20 }, // Adjust gap based on screen size
          }}>
          {[
            "Truck Dispatching services",
            "Truck dispatch billing",
            "Factoring services",
          ].map((text, index) => (
            <Box
              key={index}
              onClick={() => navigate("/ServicesDetails")}
              component="div"
              sx={{
                textAlign: "center",
                width: { xs: "150px", sm: "200px" }, // Adjust width for smaller screens
                cursor: "pointer",
                marginBottom: { xs: "20px", sm: "0" }, // Add spacing for stacked layout
              }}>
              <img
                src={Bill}
                alt=""
                style={{ width: "100px", height: "auto" }}
              />
              <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
                {text}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Second Row */}
        <Box
          component="div"
          sx={{
            paddingTop: { xs: "30px", sm: "50px", md: "70px" },
            display: "flex",
            flexDirection: { xs: "column", sm: "row" }, // Stack items on small screens
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: 5, sm: 10, md: 20 }, // Adjust gap based on screen size
          }}>
          {[
            "Company formation",
            "Negotiate the Best Rates",
            "TONU/Detention Assistance",
          ].map((text, index) => (
            <Box
              key={index}
              component="div"
              onClick={() => navigate("/ServicesDetails")}
              sx={{
                textAlign: "center",
                width: { xs: "150px", sm: "200px" }, // Adjust width for smaller screens
                cursor: "pointer",
                marginBottom: { xs: "20px", sm: "0" }, // Add spacing for stacked layout
              }}>
              <img
                src={Bill}
                alt=""
                style={{ width: "100px", height: "auto" }}
              />
              <Typography sx={{ fontSize: { xs: "14px", sm: "16px" } }}>
                {text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Services;
