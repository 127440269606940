import React from "react";
import { Box, Typography } from "@mui/material";
import Logo from "../assets/Logo.png";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    // <Box component="div" sx={{ background: "white", height: "100%" }}>
    //   <Box>
    //     <Link to="/">
    //       <img src={Logo} width={100} height={100} alt="" />
    //     </Link>
    //   </Box>
    //   <Box
    //     sx={{
    //       paddingLeft: "400px",
    //       paddingRight: "400px",
    //       textAlign: "center",
    //       paddingBottom: "100px",
    //     }}>
    //     <Typography sx={{ fontSize: "40px" }}>Privacy Policy</Typography>
    //     <Box>
    //       <Typography variant="body1" paragraph>
    //         At HexaLinks, we prioritize your privacy and are committed to
    //         protecting your personal and business information. This Privacy
    //         Policy outlines how we collect, use, and safeguard your data when
    //         you use our services.
    //       </Typography>
    //       <Typography variant="h6" gutterBottom>
    //         1. Data Collection
    //       </Typography>
    //       <Typography variant="body1" paragraph>
    //         We collect information such as names, contact details, billing
    //         information, and business-related data necessary for our truck
    //         dispatching, billing, and company formation services.
    //       </Typography>
    //       <Typography variant="h6" gutterBottom>
    //         2. Data Usage
    //       </Typography>
    //       <Typography variant="body1" paragraph>
    //         Your data is used to provide and improve our services, process
    //         payments, communicate updates, and fulfill legal requirements.
    //       </Typography>
    //       <Typography variant="h6" gutterBottom>
    //         3. Data Protection
    //       </Typography>
    //       <Typography variant="body1" paragraph>
    //         We implement secure measures to protect your data from unauthorized
    //         access, breaches, and misuse.
    //       </Typography>
    //       <Typography variant="h6" gutterBottom>
    //         4. Sharing Information
    //       </Typography>
    //       <Typography variant="body1" paragraph>
    //         Your data is shared only with trusted partners required to deliver
    //         our services and comply with legal obligations.
    //       </Typography>
    //       <Typography variant="h6" gutterBottom>
    //         5. Your Rights
    //       </Typography>
    //       <Typography variant="body1" paragraph>
    //         You can access, update, or request the deletion of your data by
    //         contacting us at dispatch@hexalinksllc.com.
    //       </Typography>
    //     </Box>
    //   </Box>
    // </Box>
    <Box component="div" sx={{ background: "white", minHeight: "100vh" }}>
      {/* Logo Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "flex-start" },
          padding: { xs: "20px", sm: "20px 50px" },
        }}>
        <Link to="/">
          <img
            src={Logo}
            alt="Logo"
            style={{
              width: "80px",
              height: "80px",
              objectFit: "contain",
            }}
          />
        </Link>
      </Box>

      {/* Privacy Policy Content */}
      <Box
        sx={{
          paddingLeft: { xs: "20px", sm: "50px", md: "400px" },
          paddingRight: { xs: "20px", sm: "50px", md: "400px" },
          textAlign: "center",
          paddingBottom: "50px",
        }}>
        <Typography
          sx={{
            fontSize: { xs: "28px", sm: "36px", md: "40px" },
            marginBottom: "20px",
          }}>
          Privacy Policy
        </Typography>
        <Box>
          <Typography variant="body1" paragraph>
            At HexaLinks, we prioritize your privacy and are committed to
            protecting your personal and business information. This Privacy
            Policy outlines how we collect, use, and safeguard your data when
            you use our services.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: { xs: "18px", sm: "20px" },
              marginTop: "20px",
            }}>
            1. Data Collection
          </Typography>
          <Typography variant="body1" paragraph>
            We collect information such as names, contact details, billing
            information, and business-related data necessary for our truck
            dispatching, billing, and company formation services.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: { xs: "18px", sm: "20px" },
              marginTop: "20px",
            }}>
            2. Data Usage
          </Typography>
          <Typography variant="body1" paragraph>
            Your data is used to provide and improve our services, process
            payments, communicate updates, and fulfill legal requirements.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: { xs: "18px", sm: "20px" },
              marginTop: "20px",
            }}>
            3. Data Protection
          </Typography>
          <Typography variant="body1" paragraph>
            We implement secure measures to protect your data from unauthorized
            access, breaches, and misuse.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: { xs: "18px", sm: "20px" },
              marginTop: "20px",
            }}>
            4. Sharing Information
          </Typography>
          <Typography variant="body1" paragraph>
            Your data is shared only with trusted partners required to deliver
            our services and comply with legal obligations.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: { xs: "18px", sm: "20px" },
              marginTop: "20px",
            }}>
            5. Your Rights
          </Typography>
          <Typography variant="body1" paragraph>
            You can access, update, or request the deletion of your data by
            contacting us at dispatch@hexalinksllc.com.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
